<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="fl department_div">
        <el-tree
          class="deptTree"
          default-expand-all
          node-key="id"
          highlight-current
          :data="deptTree"
          :props="defaultProps"
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
          :render-content="renderContent"
        >
        </el-tree>
      </div>
      <div class="table_right">
        <div class="mainList_operation clearfloat">
          <div class="mainList_operation_inline_block">
            <div class="mainList_operation_search">
              <!-- <el-input
                placeholder="请在左侧选择部门"
                clearable
                :value="parameter.deptName"
                class="input-with-select"
                readonly
                style="margin-right: 20px"
              >
                <span slot="prepend">部门</span>
              </el-input> -->

              <el-input
                v-if="type != 'group'"
                placeholder="请输入内容"
                clearable
                v-model="parameter.val"
                class="input-with-select project_width"
              >
                <span slot="prepend">用户名</span>
                <el-button
                  slot="append"
                  style="width: 35px; line-height: 30px"
                  icon="el-icon-search"
                  @click="pageChangeHandler(1)"
                ></el-button>
              </el-input>
            </div>
          </div>
          <div class="fr">
            <el-button type="primary" icon="el-icon-plus" @click="add" v-if="type == 'user'"
              >新增</el-button
            >
            <span v-else-if="type == 'group'">
              <el-button
                type="success"
                icon="el-icon-plus"
                @click="selectGroupLeader"
                v-if="isGroupLeaderAddBtnShow"
                >分配</el-button
              >
            </span>
          </div>
        </div>
        <div class="mainList_content">
          <div class="mainList_content_sub">
            <el-tabs v-model="type" class="fullScreenMainHeader" @tab-click="handleTabsClick">
              <el-tab-pane name="user">
                <span slot="label">用户</span>
              </el-tab-pane>

              <el-tab-pane name="group" v-if="selectDeptType == 'group'">
                <span slot="label">用户组</span>
              </el-tab-pane>
            </el-tabs>

            <template v-if="type == 'user'">
              <el-table
                ref="multipleTable"
                border
                :data="tableData"
                tooltip-effect="dark"
                height="string"
                :highlight-current-row="true"
                v-loading="loading"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  type="index"
                  align="center"
                  width="70"
                  :show-overflow-tooltip="false"
                ></el-table-column>
                <!-- <el-table-column prop="loginName" align="center" label="登录名"></el-table-column> -->
                <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                <el-table-column prop="deptNames" align="center" label="部门"></el-table-column>
                <el-table-column prop="postName" align="center" label="岗位"></el-table-column>
                <el-table-column prop="major" align="center" label="专业"></el-table-column>
                <el-table-column
                  prop="entryDate"
                  sortable
                  min-width="110"
                  align="center"
                  label="入职日期"
                >
                  <template slot-scope="scope">
                    {{ scope.row.entryDate | dateFormat }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="graduationDate"
                  sortable
                  min-width="110"
                  align="center"
                  label="毕业时间"
                >
                  <template slot-scope="scope">
                    {{ scope.row.graduationDate | dateFormat }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="160">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      class="text_Edit_Bgc"
                      v-if="permission(['EDIT_USER'])"
                      @click.stop="edit(scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      class="text_CHANG_POST_Bgc"
                      size="small"
                      v-if="permission(['CHANG_POST'])"
                      @click.stop="diao_g(scope.row)"
                      >调岗</el-button
                    >
                    <el-popover placement="bottom" width="250" trigger="hover">
                      <el-button
                        type="text"
                        size="small"
                        class="text_RESET_PASSWORD_Bgc"
                        v-if="permission(['RESET_PASSWORD'])"
                        @click.stop="pwe(scope.row)"
                        >重置密码</el-button
                      >
                      <el-button
                        type="text"
                        size="small"
                        class="text_ROLE_ASSOCIATION_Bgc"
                        v-if="permission(['ROLE_ASSOCIATION'])"
                        @click="roleAssociation(scope.row.id)"
                        >角色关联</el-button
                      >
                      <el-button
                        type="text"
                        size="small"
                        class="text_Remove_Bgc"
                        v-if="permission(['DEL_USER'])"
                        @click="del(scope)"
                        >删除</el-button
                      >

                      <el-button
                        type="text"
                        size="small"
                        class="text_QUIT_USER_Bgc"
                        v-if="permission(['QUIT_USER'])"
                        @click="del_lz(scope)"
                        >离职</el-button
                      >
                      <span slot="reference" class="more">更多</span>
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @current-change="pageChangeHandler"
                @size-change="handleSizeChange"
                :current-page="parameter.pageNow"
                :page-size="parameter.pageSize"
                prev-text="上一页"
                next-text="下一页"
                :total="parameter.total"
              >
              </el-pagination>
            </template>

            <template v-else-if="type == 'group'">
              <el-table
                ref="groupLeaderTable"
                border
                :data="groupLeaderTableData"
                tooltip-effect="dark"
                height="string"
                :highlight-current-row="true"
                :key="Math.random()"
                v-loading="loading"
              >
                <el-table-column
                  align="center"
                  prop="deptLeaderName"
                  label="组长"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="deptHeadManagerName"
                  label="主管经理"
                ></el-table-column>
                <el-table-column
                  prop="deptSeniorManagerName"
                  align="center"
                  label="分管高管"
                ></el-table-column>
                <el-table-column label="操作" align="center" fixed="right">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      class="text_Edit_Bgc"
                      @click.stop="editGroup(scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      class="text_Remove_Bgc"
                      @click="delGroup(scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
      </div>
    </div>

    <Edit :options="form" :isShow.sync="showEditDialog" v-if="showEditDialog"></Edit>

    <EditDialog
      :isShow.sync="showRoleAssociation"
      :loading.sync="roleAssociationLoading"
      @submit="roleAssociationSubmit"
    >
      <div slot="title" class="title">角色关联</div>
      <div slot="content" class="content" v-loading="roleAssociationContentLoading">
        <!-- <el-transfer
          class="tran_style"
          v-model="myUserRoles"
          filterable
          :data="ownRoles"
          :titles="['未关联的角色', '已关联的角色']"
        >
        </el-transfer> -->
        <div class="flex_square">
          <div class="tree-tranfer tree-tranfer-left">
            <!-- <el-input placeholder="输入关键字进行过滤" v-model="leftFilter"> </el-input> -->
            <div class="tree-tranfer-tite">未关联的角色</div>
            <el-tree
              class="filter-tree"
              :props="{
                children: 'children',
                label: 'roleName',
              }"
              :default-expand-all="true"
              :data="dataLeft"
              show-checkbox
              ref="treeLeft"
              node-key="id"
            >
            </el-tree>
          </div>
          <div class="transfer-btns">
            <el-button
              icon="el-icon-arrow-left"
              :disabled="!dataRight.length"
              type="primary"
              @click="deleteItems"
            ></el-button>
            <el-button
              icon="el-icon-arrow-right"
              :disabled="!dataLeft.length"
              type="primary"
              @click="addItems"
            ></el-button>
          </div>
          <div class="tree-tranfer tree-tranfer-right">
            <div class="tree-tranfer-tite">已关联的角色</div>
            <div class="filter-tree">
              <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
              <div style="margin: 15px 0"></div>
              <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                <el-checkbox
                  style="display: block"
                  v-for="item in dataRight"
                  :label="item.roleName"
                  :key="item.id"
                  >{{ item.roleName }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
    </EditDialog>

    <!-- 调岗弹框 -->
    <EditDialog
      width="600px"
      :isShow.sync="DLshowEditDialog"
      :loading.sync="editDialogLoading"
      @submit="dg"
    >
      <div slot="title" class="title">调岗</div>
      <div slot="content" class="content">
        <el-form label-width="7em" :model="dg_form" ref="dg_form">
          <el-form-item label="所属部门" prop="originalDepartment" ref="originalDepartment">
            <el-select :value="dg_form.originalDepartment" multiple disabled>
              <el-option
                v-for="n in departmentData"
                :key="n.id"
                :label="n.deptName"
                :value="n.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="调入部门" prop="purposeDepartment" ref="purposeDepartment">
            <el-select
              ref="select"
              v-model="dg_form.purposeDepartment"
              multiple
              @visible-change="visibleChange"
              :popper-append-to-body="false"
              placeholder="请选择部门"
            >
              <el-option
                v-for="item in departmentData"
                :key="item.id"
                :label="item.deptName"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <DeptTreeSelectDialog
              v-if="isDeptTreeShow"
              :isDeptTreeShow.sync="isDeptTreeShow"
              :selectedTreeData="dg_form.purposeDepartment"
              @getDeptList="onDeptSubmit"
            ></DeptTreeSelectDialog>
          </el-form-item>

          <el-form-item label="现任岗位" prop="originalPost" ref="originalPost">
            <el-select
              :value="dg_form.originalPost"
              disabled
              placeholder="请选择"
              clearable
              @change="$forceUpdate()"
              filterable
            >
              <el-option
                v-for="n in postList"
                :key="n.id"
                :label="n.postNames"
                :value="n.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="调入岗位" prop="purposePost" ref="purposePost">
            <el-select
              v-model="dg_form.purposePost"
              placeholder="请选择"
              @change="$forceUpdate()"
              clearable
              filterable
            >
              <el-option
                v-for="n in purposePostList"
                :key="n.id"
                :label="n.postNames"
                :value="n.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="备注" prop="remark" ref="remark">
            <el-input v-model="dg_form.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>

    <EditDialog
      width="600px"
      :isShow.sync="showPassWordEditDialog"
      :loading.sync="PassWordLoading"
      @submit="PassWord_dg"
    >
      <div slot="title" class="title">重置密码</div>
      <div slot="content" class="content">
        <el-form label-width="7em" :model="passWordForm" ref="password_form">
          <el-form-item
            label="管理员key"
            class="no-autofill-pwd"
            prop="authorityKey"
            ref="authorityKey"
          >
            <el-input
              v-model="passWordForm.authorityKey"
              :show-password="true"
              placeholder="请输入key"
              @keyup.enter.native="PassWord_dg"
            ></el-input>
          </el-form-item>

          <el-form-item label="密码" prop="password" class="no-autofill-pwd" ref="password">
            <el-input
              type="text"
              v-model="passWordForm.newPassword"
              :show-password="true"
              placeholder="请输入密码"
              @keyup.enter.native="PassWord_dg"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="确认密码" prop="re_password" ref="re_password">
                        <el-input v-model="passWordForm.re_password" placeholder="请确认密码" ></el-input>
                    </el-form-item> -->
        </el-form>
      </div>
    </EditDialog>
    <!-- 离职弹窗 -->
    <el-dialog append-to-body title="离职" :visible.sync="departDialog" width="50%">
      <el-form ref="departForm" :model="departForm" :rules="rulesDepart" label-width="100px">
        <el-form-item label="最后工作日" prop="departDate" ref="departDate">
          <el-date-picker
            value-format="timestamp"
            v-model="departForm.departDate"
            type="date"
            placeholder="请选择最后工作日时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="离职原因" prop="departReason" ref="departReason">
          <el-input
            type="textarea"
            v-model="departForm.departReason"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="离职方式" prop="departType" ref="departType">
          <el-select v-model="departForm.departType" placeholder="请选择" filterable>
            <el-option
              v-for="item in departStatus"
              :key="item.dictVal"
              :label="item.dictName"
              :value="item.dictVal"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="相关附件" prop="departFiles" ref="departFiles">
          <UploadList v-model="uploadData" :staffId="departForm.staffId"></UploadList>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="departDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitDepart" :loading="departLoading">确 定</el-button>
      </div>
    </el-dialog>

    <EditDialog
      width="600px"
      :isShow.sync="isGroupLeaderDialogShow"
      :loading.sync="groupLeaderDialogLoading"
      @submit="onChangeGroupLeaderChange"
    >
      <div slot="title" class="title">分配用户组管理员</div>
      <div slot="content" class="content">
        <el-form label-width="8em" :model="groupDialogData" ref="groupForm" :rules="groupRules">
          <el-form-item label="小组" prop="deptName" ref="deptName">
            <el-input v-model="groupDialogData.deptName" readonly></el-input>
          </el-form-item>

          <el-form-item label="组长" prop="deptLeader" ref="deptLeader">
            <ChoiceData
              style="margin-right: 15px"
              :loading="selectUserListLoading"
              :configure="{
                request: this.$api.user.listStaff,
                type: 'radio',
                params: {
                  deptId: groupDialogData.deptId,
                },
              }"
              :projectManagerDlgVisible="userSelectDialogVisible"
              :deptCompetentManagerList="userList"
              @choiceDataChange="deptLeaderChange"
              @close="onDialogClose"
            >
              <el-input
                v-model="groupDialogData.deptLeaderName"
                placeholder="请选择组长"
                @focus="getDeptUserList"
                slot="button"
              >
                <i
                  v-show="groupDialogData.deptLeader"
                  slot="suffix"
                  @click.stop="handleClear(0, $event)"
                  class="el-input__icon el-icon-error err-style"
                ></i>
              </el-input>
              <div class="mainList_operation clearfloat" slot="search">
                <div class="mainList_operation_search">
                  <el-input
                    class="item"
                    style="margin-right: 20px"
                    placeholder="部门"
                    readonly
                    v-model="deptCompetentManager.deptName"
                  ></el-input>
                  <el-input
                    class="item"
                    placeholder="姓名"
                    clearable
                    v-model="deptCompetentManager.userName"
                  ></el-input>
                  <el-button type="primary" plain icon="el-icon-search" @click="serachUserList"
                    >搜索</el-button
                  >
                </div>
              </div>
              <template slot="tableColumn-left">
                <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                <el-table-column align="center" prop="postName" label="岗位"> </el-table-column>
              </template>
            </ChoiceData>
          </el-form-item>

          <el-form-item label="是否为主管经理" prop="isDeptManager" ref="isDeptManager">
            <el-radio
              @input="onInputIsDeptManager"
              v-model="groupDialogData.isDeptManager"
              :label="true"
              >是</el-radio
            >
            <el-radio v-model="groupDialogData.isDeptManager" :label="false">否</el-radio>
          </el-form-item>
          <el-form-item
            label="主管经理"
            prop="deptHeadManager"
            ref="deptHeadManager"
            v-if="groupDialogData.deptLeader && !groupDialogData.isDeptManager"
          >
            <ChoiceData
              style="margin-right: 15px"
              :loading="selectUserListLoading"
              :configure="{
                request: this.$api.project.listDepartmentStaff,
                type: 'radio',
                params: {
                  deptId: groupDialogData.parentId,
                },
              }"
              :projectManagerDlgVisible="deptCompetentManagerSelectDialogVisible"
              :deptCompetentManagerList="userList"
              @choiceDataChange="deptCompetentManagerChange"
              @close="ondeptCompetentManagerDialogClose"
            >
              <el-input
                v-model="groupDialogData.deptHeadManagerName"
                placeholder="请选择部门主管经理"
                @focus="getDeptCompetentManagerList"
                slot="button"
              >
                <i
                  v-show="groupDialogData.deptHeadManager"
                  slot="suffix"
                  @click.stop="handleClear(1, $event)"
                  class="el-input__icon el-icon-error err-style"
                ></i>
              </el-input>
              <div class="mainList_operation clearfloat" slot="search">
                <div class="mainList_operation_search">
                  <el-input
                    class="item"
                    style="margin-right: 20px"
                    placeholder="部门"
                    readonly
                    v-model="deptCompetentManager.deptName"
                  ></el-input>
                  <el-input
                    class="item"
                    placeholder="姓名"
                    clearable
                    v-model="deptCompetentManager.userName"
                  ></el-input>
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-search"
                    @click="serachDeptCompetentManagerList"
                    >搜索</el-button
                  >
                </div>
              </div>
              <template slot="tableColumn-left">
                <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                <el-table-column align="center" prop="postName" label="岗位"> </el-table-column>
              </template>
            </ChoiceData>
          </el-form-item>

          <el-form-item label="分管高管" prop="deptSeniorManagerList" ref="deptSeniorManager">
            <el-input
              v-model="groupDialogData.deptSeniorManagerName"
              placeholder="请选择分管高管"
              @focus="getSeniorExecutiveList"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>

    <el-dialog
      :visible.sync="checkBoxDialogVisable"
      v-if="checkBoxDialogVisable"
      :before-close="cancelSelect"
      append-to-body
      width="65%"
    >
      <div slot="title" style="color: #409eff">选择分管高管</div>
      <div class="mainList_operation_search">
        <el-input
          class="mainList_operation_search_Name"
          style="width: 200px; margin: 0 8px 8px 8px"
          placeholder="姓名"
          clearable
          v-model="checkBoxDialogParameter.userName"
        ></el-input>
        <el-button type="primary" plain icon="el-icon-search" @click="getCheckBoxDialogList()"
          >搜索</el-button
        >
      </div>
      <el-table
        v-loading="checkBoxDialogLoading"
        :data="checkBoxDialogList"
        style="width: 100%"
        max-height="400"
        border
        id="selectedTable"
        :row-key="getRowKey"
        @selection-change="handleCurrentChange"
        @row-click="handRowClick"
        :row-style="rowClass"
        ref="selectedTable"
      >
        <el-table-column type="selection" label="选择" :reserve-selection="true" width="60">
        </el-table-column>
        <el-table-column align="center" prop="deptNames" label="部门"> </el-table-column>
        <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
        <el-table-column align="center" prop="postName" label="岗位"> </el-table-column>
      </el-table>
      <div style="float: right">
        <el-pagination
          @size-change="checkBoxDialogHandleSizeChange"
          @current-change="checkBoxDialogPageChangeHandler"
          :current-page="checkBoxDialogParameter.pageNow"
          :page-size="checkBoxDialogParameter.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="total, prev, pager, next, slot, jumper"
          :total="checkBoxDialogParameter.total"
        >
          <span class="el-pagination__jump e_a_pagination">
            <el-input size="mini" v-model.number="checkBoxDialogParameter.pageSize"></el-input>
            <span style="padding-top: 1px">条/页</span>
          </span>
        </el-pagination>
      </div>
      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button type="success" style="min-width: 120px; margin: 0 25px" @click="saveSelectedFn"
          >确 定</el-button
        >
        <el-button
          type="warning"
          style="min-width: 120px; margin: 0 25px"
          plain
          @click="cancelSelect"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'

export default {
  components: {
    Edit: () => import('./Edit.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    UploadList: () => import('@/components/upload/ContractList.vue'),
    DeptTreeSelectDialog: () => import('@/components/dept/DeptTreeSelectDialog.vue'),
    ChoiceData: () => import('@/components/ChoiceData.vue'),
  },
  data() {
    return {
      groupRules: {
        deptLeader: [{ required: true, message: '请选择组长', trigger: 'change' }],
        deptHeadManager: [{ required: true, message: '请选择主管经理', trigger: 'change' }],
        deptSeniorManagerList: [{ required: true, message: '请选择分管高管', trigger: 'change' }],
      },
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      type: 'user', //页签 用户/用户组 默认用户
      selectDeptType: '', //当前点击的部门
      groupDialogData: {
        deptId: '',
        deptName: '',
        deptLeader: '',
        deptLeaderName: '',
        deptHeadManager: '',
        deptHeadManagerName: '',
        deptSeniorManager: '',
        deptSeniorManagerName: '',
        deptSeniorManagerList: [],
      },
      isGroupLeaderDialogShow: false,
      groupLeaderDialogLoading: false,
      form: {},
      tableData: [],
      groupLeaderTableData: [], //用户组数据
      loading: false,
      showEditDialog: false,
      editDialogLoading: false,
      activeId: null,
      showRoleAssociation: false,
      roleAssociationContentLoading: false,
      roleAssociationLoading: false,
      ownRoles: [],
      myUserRoles: [],
      departmentData: [], //扁平化部门数组
      deptTree: [], // 部门 树形列表
      // 部门 树形对象 结构
      defaultProps: {
        children: 'children',
        label: 'deptName',
      },
      conttll: 'loginName',
      DLshowEditDialog: false,
      dg_form: {},
      postList: [],
      //重置密码字段
      showPassWordEditDialog: false,
      PassWordLoading: false,
      passWordForm: {},
      departLoading: false, //离职弹窗确认键
      departDialog: false, //离职弹窗
      departForm: {}, //离职信息
      departStatus: [], //离职方式
      uploadData: [], //离职文件信息
      rulesDepart: {
        departDate: [
          { required: true, message: '请选择最后工作日时间', trigger: ['blur', 'change'] },
        ],
        // 只需要最后工作日时间
        // departReason: [{ required: true, message: '请输入离职原因', trigger: ['blur', 'change'] }],
        // departType: [{ required: true, message: '请选择离职方式', trigger: ['blur', 'change'] }],
      },
      dataRight: [],
      dataLeft: [],
      dataLeft: [],
      checkedCities: [],
      checkAll: false,
      isGroupLeaderAddBtnShow: false,

      renderContent: function (h, { node, data, store }) {
        let addElement = arguments[0]
        if (data.level == 1) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconcompany iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 2) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconconnections iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 3) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconCustomermanagement iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        }
      },

      isDeptTreeShow: false,
      purposePostList: [], //目标部门对应的目标岗位

      userList: [], //普通用户数组
      selectUserListLoading: false,
      userSelectDialogVisible: false,
      deptCompetentManagerSelectDialogVisible: false,
      deptCompetentManager: {
        deptId: '',
        deptName: '',
        userName: '',
      },

      checkBoxDialogParameter: {
        deptId: '',
        deptName: '',
        userName: '',
        pageNow: 1,
        total: 0,
        pageSize: 50,
      },
      checkBoxDialogLoading: false,
      checkBoxDialogVisable: false,
      checkBoxDialogList: [],
      temporaryList: [],
      selectRow: [], //控制选中行变色
      arrSelect: [], //已选人员
    }
  },
  watch: {
    uploadData: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        let arr = []
        if (this.uploadData.length > 0) {
          for (let i = 0; i < this.uploadData.length; i++) {
            const e = {
              attachmentType: 'DEPART',
              attachmentName: this.uploadData[i].attachmentName,
              attachmentPath: this.uploadData[i].attachmentPath,
              remark: this.uploadData[i].remark,
              staffId: this.uploadData[i].staffId,
            }
            if (this.uploadData[i].id) e.id = this.uploadData[i].id
            arr.push(e)
          }
        }
        this.departForm.departFiles = arr
      },
    },
    temporaryList: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.selectRow = []
        if (val.length > 0) {
          this.selectRow = val.map(v => v.id)
        }
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  provide() {
    return {
      re: this.re,
    }
  },
  created() {
    this.$api.dict.listSysDictData('DEPART_STATUS', true).then(res => {
      this.departStatus = res.data
    })
    this.getDeptTree()
    this.getDeptList()
    this.getPostList()
    this.getData()
  },
  methods: {
    handleTabsClick() {
      if (this.type == 'group') {
        this.getGroupLeaderData()
      }
    },
    getGroupLeaderData() {
      this.loading = true
      this.groupLeaderTableData = []
      this.$api.user
        .listDeptGroupLeader(Object.assign(this.parameter))
        .then(res => {
          this.loading = false
          this.groupLeaderTableData = res.data || []
          if (this.groupLeaderTableData.length == 0) {
            this.isGroupLeaderAddBtnShow = true
          } else {
            this.isGroupLeaderAddBtnShow = false
          }
          this.groupLeaderTableData.forEach(item => {
            if (item.deptSeniorManager) {
              item.deptSeniorManagerList = item.deptSeniorManager.split(',')
            }
          })
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },

    handleCheckAllChange(val) {
      if (val) {
        this.dataRight.forEach(v => {
          this.checkedCities.push(v.roleName)
        })
      } else {
        this.checkedCities = []
      }
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.dataRight.length
    },
    deleteItems() {
      let arr = []
      this.checkedCities.forEach(v => {
        this.dataRight.forEach((n, i) => {
          if (v === n.roleName) {
            arr.push(n)
            this.dataRight.splice(i, 1)
          }
        })
      })
      arr.forEach(n => {
        if (n.parentId == 0) {
          if (!n.children) {
            n.children = []
          }
          this.dataLeft.push(n)
        }
        this.dataLeft.forEach(v => {
          if (n.parentId == v.id) {
            v.children.push(n)
          }
        })
      })
    },
    addItems() {
      let list = this.$refs.treeLeft.getCheckedNodes()
      list = list.filter(v => !v.move)
      list.forEach(item => {
        let newDataLeft = [...this.dataLeft]
        newDataLeft = newDataLeft.filter(n => item.id !== n.id)
        newDataLeft.forEach(n => {
          if (n.children) {
            n.children = n.children.filter(k => item.id !== k.id)
          }
        })
        this.dataLeft = newDataLeft
      })
      this.$refs.treeLeft.setCheckedNodes([])
      // 将选择的项添加到右侧
      this.dataRight.push(...list)
    },

    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    transfer(originUser, targetUser) {
      this.loading = true
      this.$api.user
        .turnOver({
          originUser,
          targetUser,
        })
        .then(res => {
          this.loading = false
          this.$message.success('操作成功！')
          this.getData()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    choiceDataChange(r) {
      this.form.deptId = r.id
    },
    roleAssociation(id) {
      this.roleAssociationContentLoading = true
      this.ownRoles = []
      this.myUserRoles = []
      this.checkedCities = []
      this.dataRight = []
      this.dataLeft = []
      this.checkAll = false
      this.activeId = id
      this.$api.user
        .findRoleByUserId(id)
        .then(res => {
          res.data.ownRoles.forEach(item => {
            if (item.parentId == 0 && !item.children) {
              item.children = []
            }
            this.ownRoles.push({
              key: item.id,
              label: item.roleName,
            })
          })
          let arr = initTreeData(res.data.ownRoles)
          arr.forEach(v => {
            if (v.parentId == 0 && v.children && v.children.length > 0) {
              v.disabled = true
              v.move = true
            }
          })
          this.dataLeft = arr
          this.dataRight = res.data.myUserRoles
          res.data.myUserRoles.forEach(item => {
            this.ownRoles.push({
              key: item.id,
              label: item.roleName,
            })
            this.myUserRoles.push(item.id)
          })
          this.roleAssociationContentLoading = false
        })
        .catch(err => {
          this.roleAssociationContentLoading = false
          console.log(err)
        })

      this.showRoleAssociation = true
    },
    roleAssociationSubmit() {
      let arr = []
      this.dataRight.forEach(item => {
        arr.push(item.id)
      })
      this.roleAssociationLoading = true
      this.$api.user
        .associationStaffRole({
          staffId: this.activeId,
          // roleIds: this.myUserRoles,
          roleIds: arr,
        })
        .then(res => {
          this.roleAssociationLoading = false
          this.showRoleAssociation = false
          this.$message.success('操作成功！')
        })
        .catch(err => {
          this.roleAssociationLoading = false
          console.log(err)
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    add() {
      this.showEditDialog = true
    },
    edit(row) {
      this.form = {}
      this.form.id = row.id
      this.form.is_edit = true
      this.showEditDialog = true
    },
    pwe(row) {
      this.passWordForm = {}
      this.passWordForm.staffId = row.id
      this.showPassWordEditDialog = true
    },
    getData() {
      this.loading = true
      if (this.conttll == 'userName') {
        this.parameter.userName = this.parameter.val
        this.parameter.loginName = ''
      } else {
        this.parameter.loginName = this.parameter.val
        this.parameter.userName = ''
      }

      this.$api.user
        .listStaff(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data.records) {
            res.data.records.forEach(item => {
              item.labelList = item.labelList ? item.labelList : []
            })
            this.tableData = res.data.records
          }
          this.parameter.total = ~~res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    del(scope) {
      console.log(scope)
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.user
            .del(scope.row.id)
            .then(res => {
              this.$message.success('删除成功！')

              var hasLastPage =
                this.parameter.pageSize * this.parameter.pageNow >= this.parameter.total
              var lastData = this.tableData.length < 2
              if (lastData && hasLastPage && this.parameter.pageNow > 1) {
                this.parameter.pageNow--
              }
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    /**
     * 点击 "部门" 树形列表
     * @param data      点击获取数据
     */
    handleNodeClick(data) {
      if (data.level == 3) {
        //选择的是小组
        this.selectDeptType = 'group'
      } else if (data.level == 2) {
        this.selectDeptType = 'dept'
      } else {
        this.selectDeptType = ''
      }
      this.isGroupLeaderAddBtnShow = false
      this.type = 'user'
      this.parameter.pageNow = 1
      this.parameter.userName = ''
      this.parameter.loginName = ''
      this.parameter.deptId = data.id
      this.parameter.deptName = data.deptName
      this.parameter.parentId = data.parentId
      this.tableKey++
      this.groupLeaderTableData = []
      this.getUserList() /** 加载 "用户" 列表数据 **/
    },
    selectGroupLeader() {
      this.groupLeaderDialogLoading = true
      this.groupDialogData = {
        deptId: this.parameter.deptId,
        parentId: this.parameter.parentId,
        deptName: this.parameter.deptName,
        deptLeader: '',
        isDeptManager: false,
        deptLeaderName: '',
        deptHeadManager: '',
        deptHeadManagerName: '',
        deptSeniorManager: '',
        deptSeniorManagerName: '',
        deptSeniorManagerList: [],
      }
      this.isGroupLeaderDialogShow = true
      this.groupLeaderDialogLoading = false
      this.$nextTick(() => {
        this.$refs.groupForm.clearValidate()
      })
    },
    onInputIsDeptManager(val) {
      if (val) {
        this.groupDialogData.deptHeadManagerName = ''
        this.groupDialogData.deptHeadManager = ''
      }
    },
    re() {
      this.showEditDialog = false
      this.form = {}
      this.getData()
    },
    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .listDept()
        .then(res => {
          this.departmentData = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getDeptTree() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptTree = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    /** 加载 "用户" 列表数据 **/
    getUserList() {
      this.loading = true
      this.$api.user
        .listStaff(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data.records) {
            res.data.records.forEach(item => {
              item.labelList = item.labelList ? item.labelList : []
            })
          }
          this.tableData = res.data.records
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 获取部门所有成员
    getDeptUserList() {
      this.userList = []
      this.selectUserListLoading = true
      this.deptCompetentManager.deptName = this.groupDialogData.deptName
      this.$api.user
        .listStaff({
          pageSize: 1000000,
          deptId: this.groupDialogData.deptId,
        })
        .then(res => {
          if (res.data.records) {
            this.userList = res.data.records
            const leaderIndex = this.userList.findIndex(
              item => item.id == this.groupDialogData.deptLeader
            )
            if (leaderIndex !== -1) {
              let deleteArr = this.userList.splice(leaderIndex, 1)
              deleteArr[0].Selected = 1
              this.userList.unshift(deleteArr[0])
            }
            this.selectUserListLoading = false
            this.$forceUpdate()
          }
        })

      this.userSelectDialogVisible = true
    },
    // 获取部门主管经理
    getDeptCompetentManagerList() {
      this.userList = []
      this.selectUserListLoading = true
      this.deptCompetentManager.deptName = this.groupDialogData.deptName
      this.$api.project
        .listDepartmentStaff({
          pageSize: 1000000,
          deptId: this.groupDialogData.parentId, //当前小组父类（部门）id
        })
        .then(res => {
          if (res.data.records) {
            this.userList = res.data.records
            const leaderIndex = this.userList.findIndex(
              item => item.id == this.groupDialogData.deptHeadManager
            )
            if (leaderIndex !== -1) {
              let deleteArr = this.userList.splice(leaderIndex, 1)
              deleteArr[0].Selected = 1
              this.userList.unshift(deleteArr[0])
            }
            this.selectUserListLoading = false
            this.$forceUpdate()
          }
        })
      this.deptCompetentManagerSelectDialogVisible = true
    },
    del_lz(scope) {
      this.departForm = {}
      this.departForm.staffId = scope.row.id
      this.uploadData = []
      this.departDialog = true
    },
    submitDepart() {
      this.$refs.departForm.validate(valid => {
        if (valid) {
          this.departLoading = true
          this.$api.user
            .dimission(this.departForm)
            .then(res => {
              this.departLoading = false
              this.departDialog = false
              this.$message.success('离职成功！')
              this.getData()
            })
            .catch(err => {
              this.departLoading = false
              this.departDialog = false
              console.log(err)
            })
        }
      })
    },
    diao_g(row) {
      this.getPurposePostList(row.deptList)
      this.dg_form.staffId = row.id
      this.dg_form.originalDepartment = row.deptList
      this.dg_form.originalPost = row.postId
      this.dg_form.remark = null
      this.dg_form.purposeDepartment = row.deptList
      this.DLshowEditDialog = true
    },
    dg() {
      this.editDialogLoading = true
      this.$api.user
        .surveyWork(this.dg_form)
        .then(res => {
          if (res.code == 200) {
            this.$message.success('调岗成功！')
            this.getData()
            this.dg_form = {}
            this.DLshowEditDialog = false
            this.editDialogLoading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.DLshowEditDialog = false
          this.editDialogLoading = false
        })
    },
    PassWord_dg() {
      this.$api.user
        .resetPassword(this.passWordForm)
        .then(res => {
          if (res.code == 200) {
            this.$message.success('重置成功！')
            this.showPassWordEditDialog = false
            this.getData()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    visibleChange() {
      // 关闭选择下拉框，跳出弹框
      this.$refs.select.blur()
      this.isDeptTreeShow = true
    },

    onDeptSubmit(value) {
      if (value && value.length > 0) {
        this.dg_form.purposeDepartment = value.map(item => item.id)
        this.getPurposePostList(this.dg_form.purposeDepartment)
      }
    },

    /** 加载 "岗位" 列表数据 **/
    getPostList() {
      this.$api.sysConfig
        .listPost()
        .then(res => {
          this.postList = []
          res.data.forEach(e => {
            e.postNames = e.deptName + ' - ' + e.postName
          })
          this.postList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    /** 加载 "目标岗位" 列表数据 **/
    getPurposePostList(gjz = []) {
      let obj = {}
      if (gjz.length > 0) {
        obj = { deptIdList: gjz }
      }
      this.$api.sysConfig
        .listPost(obj)
        .then(res => {
          this.purposePostList = []
          res.data.forEach(e => {
            e.postNames = e.deptName + ' - ' + e.postName
          })
          this.purposePostList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    //选择部门组长
    deptLeaderChange(res) {
      this.groupDialogData.deptLeaderName = res.userName
      this.groupDialogData.deptLeader = res.id
      // this.groupDialogData.isDeptManager = res.isDeptManager
      this.onDialogClose()
      this.$forceUpdate()
    },

    deptCompetentManagerChange(res) {
      this.groupDialogData.deptHeadManagerName = res.userName
      this.groupDialogData.deptHeadManager = res.id
      this.ondeptCompetentManagerDialogClose()
      this.$forceUpdate()
    },

    onDialogClose(val) {
      this.userSelectDialogVisible = val
      this.deptCompetentManager = {
        deptName: '',
        deptId: '',
        userName: '',
      }
    },

    ondeptCompetentManagerDialogClose(val) {
      this.deptCompetentManagerSelectDialogVisible = val
      this.deptCompetentManager = {
        deptName: '',
        deptId: '',
        userName: '',
      }
    },

    serachUserList() {
      this.userList = []
      this.selectUserListLoading = true
      this.$api.user
        .listStaff({
          pageSize: 1000000,
          deptId: this.groupDialogData.deptId,
          userName: this.deptCompetentManager.userName,
        })
        .then(res => {
          if (res.data.records) {
            this.userList = res.data.records
            const leaderIndex = this.userList.findIndex(
              item => item.id == this.groupDialogData.deptLeader
            )
            if (leaderIndex !== -1) {
              let deleteArr = this.userList.splice(leaderIndex, 1)
              deleteArr[0].Selected = 1
              this.userList.unshift(deleteArr[0])
            }
            this.selectUserListLoading = false
            this.$forceUpdate()
          }
        })
    },

    serachDeptCompetentManagerList() {
      this.userList = []
      this.selectUserListLoading = true
      this.$api.project
        .listDepartmentStaff({
          pageSize: 1000000,
          deptId: this.groupDialogData.parentId,
          userName: this.deptCompetentManager.userName,
        })
        .then(res => {
          if (res.data.records) {
            this.userList = res.data.records
            const leaderIndex = this.userList.findIndex(
              item => item.id == this.groupDialogData.deptHeadManager
            )
            if (leaderIndex !== -1) {
              let deleteArr = this.userList.splice(leaderIndex, 1)
              deleteArr[0].Selected = 1
              this.userList.unshift(deleteArr[0])
            }
            this.selectUserListLoading = false
            this.$forceUpdate()
          }
        })
    },

    getSeniorExecutiveList() {
      this.checkBoxDialogVisable = true
      this.arrSelect = []
      if (
        this.groupDialogData.deptSeniorManagerList &&
        this.groupDialogData.deptSeniorManagerList.length > 0
      ) {
        this.arrSelect = this.groupDialogData.deptSeniorManagerList
      }
      this.checkBoxDialogParameter.userName = null
      this.checkBoxDialogParameter.deptId = ''
      this.checkBoxDialogParameter.deptName = ''
      this.checkBoxDialogParameter.pageNow = 1
      this.getCheckBoxDialogList()
    },

    getCheckBoxDialogList() {
      this.checkBoxDialogLoading = true
      this.$api.project
        .listDepartmentHeadStaff(this.checkBoxDialogParameter)
        .then(res => {
          this.checkBoxDialogList = res.data?.records.deepClone()
          this.checkBoxDialogParameter.total = res.data?.total
          this.$nextTick(() => {
            this.checkBoxDialogList.forEach(v => {
              if (this.arrSelect.indexOf(v.id) != -1) {
                this.$refs.selectedTable.toggleRowSelection(v, true)
              } else {
                this.$refs.selectedTable.toggleRowSelection(v, false)
              }
            })
          })
          this.checkBoxDialogLoading = false
        })
        .catch(err => {
          console.log(err)
          this.checkBoxDialogLoading = false
        })
    },

    cancelSelect() {
      this.checkBoxDialogVisable = false
      this.checkBoxDialogType = ''
      this.checkBoxDialogParameter = {
        pageNow: 1,
        pageSize: 50,
        userName: '',
        deptId: '',
        deptName: '',
      }
      this.temporaryList = []
    },

    saveSelectedFn() {
      this.groupDialogData.deptSeniorManagerList = this.groupDialogData.deptSeniorManagerList || []
      this.groupDialogData.deptSeniorManagerName = this.groupDialogData.deptSeniorManagerName || ''
      this.groupDialogData.deptSeniorManagerList = this.temporaryList.map(item => item.id)
      this.groupDialogData.deptSeniorManagerName = this.temporaryList
        .map(item => item.userName)
        .join(',')
      this.$forceUpdate()
      this.checkBoxDialogVisable = false
      this.temporaryList = []
    },

    getRowKey(row) {
      return row.id
    },
    handleCurrentChange(val) {
      this.temporaryList = val
    },
    // 对所选行进行样式设置
    rowClass({ row, rowIndex }) {
      if (this.selectRow.includes(row.id)) {
        return { 'background-color': '#ede88c' }
      }
    },
    // 点击行选中
    handRowClick(row) {
      this.$refs.selectedTable.toggleRowSelection(row)
    },

    checkBoxDialogPageChangeHandler(val) {
      this.checkBoxDialogParameter.pageNow = val
      this.getSelectedList()
    },
    checkBoxDialogHandleSizeChange(val) {
      this.checkBoxDialogParameter.pageSize = val
      this.checkBoxDialogParameter.pageNow = 1
      this.getSelectedList()
    },

    onChangeGroupLeaderChange() {
      this.$refs.groupForm.validate(valid => {
        if (valid) {
          if (
            this.groupDialogData.deptSeniorManagerList &&
            this.groupDialogData.deptSeniorManagerList.length &&
            Array.isArray(this.groupDialogData.deptSeniorManagerList)
          ) {
            this.groupDialogData.deptSeniorManager =
              this.groupDialogData.deptSeniorManagerList.join(',')
          }
          if (this.groupDialogData.id) {
            this.$api.user
              .editDeptGroupLeader(Object.assign(this.groupDialogData))
              .then(res => {
                this.groupLeaderDialogLoading = false
                this.isGroupLeaderDialogShow = false
                this.$message.success('操作成功！')
                this.getGroupLeaderData()
              })
              .catch(err => {
                this.groupLeaderDialogLoading = false
                console.log(err)
              })
          } else {
            this.$api.user
              .addDeptGroupLeader(Object.assign(this.groupDialogData))
              .then(res => {
                this.groupLeaderDialogLoading = false
                this.isGroupLeaderDialogShow = false
                this.$message.success('操作成功！')
                this.getGroupLeaderData()
              })
              .catch(err => {
                this.groupLeaderDialogLoading = false
                console.log(err)
              })
          }
        }
      })
    },

    editGroup(row) {
      this.groupLeaderDialogLoading = true
      this.groupDialogData = { ...row }
      this.groupDialogData.deptId = this.parameter.deptId
      this.groupDialogData.parentId = this.parameter.parentId
      this.groupDialogData.deptName = this.parameter.deptName
      this.isGroupLeaderDialogShow = true
      this.groupLeaderDialogLoading = false
    },

    delGroup(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.user
            .deleteDeptGroupLeader(row.id)
            .then(res => {
              this.groupLeaderDialogLoading = false
              this.isGroupLeaderDialogShow = false
              this.$message.success('操作成功！')
              this.getGroupLeaderData()
            })
            .catch(err => {
              this.groupLeaderDialogLoading = false
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },

    handleClear(type, event) {
      event.preventDefault()
      if (type == 0) {
        this.groupDialogData.deptLeaderName = ''
        this.groupDialogData.deptLeader = ''
        this.groupDialogData.isDeptManager = false
      } else if (type == 1) {
        this.groupDialogData.deptHeadManagerName = ''
        this.groupDialogData.deptHeadManager = ''
      }
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.mainList {
  display: block;
}

.department_div {
  width: 256px;
  height: 100%;
  background-color: #ffffff;
  margin-right: 10px;
  padding: 10px 0px;
  overflow: auto;
}

.table_right {
  float: right;
  width: calc(100% - 266px);
  height: calc(100% - 90px);
}

.tran_style {
  white-space: nowrap;
  padding: 10px;
}
// 样式部分
.flex_square {
  display: flex;
  align-items: center;
  .tree-tranfer {
    width: 37%;
    height: 350px;
    border: 1px solid #ebeef5;
    padding: 10px;
    overflow-y: auto;
  }
  .transfer-btns {
    padding: 0px 30px;
  }
  .tree-tranfer-tite {
    font-size: 16px;
    background-color: #f5f7fa;
    text-align: center;
  }
}

/deep/.deptName {
  font-size: 14px;
}

/deep/.iconDept {
  color: #399ffb;
}
/deep/#selectedTable {
  .tableRowClassName {
    background-color: #ede88c;
  }

  tbody tr:hover > td {
    background-color: unset !important; //修改成自己想要的颜色即可
  }
}
.multipleTable {
  overflow: auto;
}
/deep/ .more {
  background-color: #aacdee;
  color: #fff;
  padding: 5px 5px;
  margin-left: 8px;
}
.text_Edit_Bgc {
  background-color: #e6a23c;
  color: #fff;
  padding: 5px 5px;
}
.text_QUIT_USER_Bgc {
  background-color: #8bee5ec2;
  color: #fff;
  padding: 5px 5px;
}
.text_RESET_PASSWORD_Bgc {
  background-color: #61becec2;
  color: #fff;
  padding: 5px 5px;
}
.text_ROLE_ASSOCIATION_Bgc {
  background-color: #6391f3c2;
  color: #fff;
  padding: 5px 5px;
}
.text_Remove_Bgc {
  background-color: #f78888;
  color: #fff;
  padding: 5px 5px;
}
</style>
